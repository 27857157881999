import { ProtoMessage } from "./message";
import * as $values from "./generated/values/values.pbe";
class BuiltinType extends ProtoMessage {
  constructor() {
    super(...arguments);
    this.value = undefined;
  }
  static create(value) {
    return value;
  }
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new this.Type());
  }
  merge(src) {
    this.value = src;
  }
}
class NullableV extends BuiltinType {
  _binaryRead(r, length) {
    let t = new this.constructor.Type();
    let x = t._binaryRead(r, length);
    return x.isNull ? null : x.v;
  }
  _binaryWrite(w) {
    if (this.value === undefined) return w;
    return new this.constructor.Type(this.value === null ? {
      isNull: true
    } : {
      v: this.value
    })._binaryWrite(w);
  }
}
export var builtin;
(function (builtin) {
  var values;
  (function (values) {
    values.Empty = 0;
    values.ListValue = 0;
    values.Struct = 0;
    values.Value = 0;
    class BoolValue extends NullableV {}
    BoolValue.Type = $values.BoolValue;
    values.BoolValue = BoolValue;
    class StringValue extends NullableV {}
    StringValue.Type = $values.StringValue;
    values.StringValue = StringValue;
    class BytesValue extends NullableV {}
    BytesValue.Type = $values.BytesValue;
    values.BytesValue = BytesValue;
    class Int32Value extends NullableV {}
    Int32Value.Type = $values.Int32Value;
    values.Int32Value = Int32Value;
    class UInt32Value extends NullableV {}
    UInt32Value.Type = $values.UInt32Value;
    values.UInt32Value = UInt32Value;
    class Int64Value extends NullableV {}
    Int64Value.Type = $values.Int64Value;
    values.Int64Value = Int64Value;
    class UInt64Value extends NullableV {}
    UInt64Value.Type = $values.UInt64Value;
    values.UInt64Value = UInt64Value;
    class FloatValue extends NullableV {}
    FloatValue.Type = $values.FloatValue;
    values.FloatValue = FloatValue;
    class DoubleValue extends NullableV {}
    DoubleValue.Type = $values.DoubleValue;
    values.DoubleValue = DoubleValue;
    class Timestamp extends NullableV {}
    Timestamp.Type = $values.Timestamp;
    values.Timestamp = Timestamp;
    values.DateRange = 0;
  })(values = builtin.values || (builtin.values = {}));
})(builtin || (builtin = {}));
