// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '../../runtime';
export class Config extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Config());
  }
}
Config._fqn = 'jwt.Config';
Config._fields = NewFields([[1, {
  name: 'accessToken',
  json: 'access_token',
  type: 11,
  ctor: () => TokenConfig
}], [2, {
  name: 'refreshToken',
  json: 'refresh_token',
  type: 11,
  ctor: () => TokenConfig
}]]);
export class TokenConfig extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new TokenConfig());
  }
}
TokenConfig._fqn = 'jwt.TokenConfig';
TokenConfig._fields = NewFields([[1, {
  name: 'secret',
  json: 'secret',
  type: 9
}], [2, {
  name: 'expiration',
  json: 'expiration',
  type: 5
}]]);
export class Tokens extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Tokens());
  }
}
Tokens._fqn = 'jwt.Tokens';
Tokens._fields = NewFields([[1, {
  name: 'accessToken',
  json: 'access_token',
  type: 9
}], [2, {
  name: 'refreshToken',
  json: 'refresh_token',
  type: 9
}]]);
