// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '@pbe/runtime';
export class ACL extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new ACL());
  }
}
ACL._fqn = 'services.secret.v1.ACL';
ACL._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'signer',
  json: 'signer',
  type: 11,
  ctor: () => Identity
}], [3, {
  name: 'signature',
  json: 'signature',
  type: 9
}], [4, {
  name: 'subject',
  json: 'subject',
  type: 11,
  ctor: () => Identity
}], [5, {
  name: 'namespaces',
  json: 'namespaces',
  type: 9
}]]);
export class Config extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Config());
  }
}
Config._fqn = 'services.secret.v1.Config';
Config._fields = NewFields([[1, {
  name: 'database',
  json: 'database',
  type: 11
}], [2, {
  name: 'encryption',
  json: 'encryption',
  type: 11,
  ctor: () => EncryptionConfig
}], [3, {
  name: 'ssh',
  json: 'ssh',
  type: 11,
  ctor: () => SSHConfig
}]]);
export class ConfigEntry extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new ConfigEntry());
  }
}
ConfigEntry._fqn = 'services.secret.v1.ConfigEntry';
ConfigEntry._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'signer',
  json: 'signer',
  type: 11,
  ctor: () => Identity
}], [3, {
  name: 'signature',
  json: 'signature',
  type: 9
}], [4, {
  name: 'namespace',
  json: 'namespace',
  type: 9
}], [5, {
  name: 'version',
  json: 'version',
  type: 5
}], [6, {
  name: 'content',
  json: 'content',
  type: 9
}]]);
export class EncryptionConfig extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new EncryptionConfig());
  }
}
EncryptionConfig._fqn = 'services.secret.v1.EncryptionConfig';
EncryptionConfig._fields = NewFields([[1, {
  name: 'keyHex',
  json: 'key_hex',
  type: 9
}]]);
export class Identity extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Identity());
  }
}
Identity._fqn = 'services.secret.v1.Identity';
Identity._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'signer',
  json: 'signer',
  type: 11,
  ctor: () => Identity
}], [3, {
  name: 'signature',
  json: 'signature',
  type: 9
}], [4, {
  name: 'name',
  json: 'name',
  type: 9
}], [5, {
  name: 'publicKey',
  json: 'public_key',
  type: 9
}], [6, {
  name: 'signPriority',
  json: 'sign_priority',
  type: 5
}], [1024, {
  name: 'aclSignatures',
  json: 'acl_signatures',
  type: 11,
  repeated: true,
  ctor: () => ACL
}], [1025, {
  name: 'acls',
  json: 'acls',
  type: 11,
  repeated: true,
  ctor: () => ACL
}], [1026, {
  name: 'configEntrySignatures',
  json: 'config_entry_signatures',
  type: 11,
  repeated: true,
  ctor: () => ConfigEntry
}], [1027, {
  name: 'identitySignatures',
  json: 'identity_signatures',
  type: 11,
  repeated: true,
  ctor: () => Identity
}]]);
export class SSHConfig extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new SSHConfig());
  }
}
SSHConfig._fqn = 'services.secret.v1.SSHConfig';
SSHConfig._fields = NewFields([[1, {
  name: 'privateKey',
  json: 'private_key',
  type: 9
}]]);
