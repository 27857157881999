import { Subject } from "rxjs";
export var rpc;
(function (rpc) {
  class Request {
    constructor(service, method, options) {
      this.service = service;
      this.method = method;
      this.options = options;
      this.req = new Subject();
      this.resp = new Subject();
    }
    close() {
      this.req.complete();
      this.resp.complete();
    }
  }
  rpc.Request = Request;
  class Transport {
    constructor() {
      this.requests = new Subject();
    }
  }
  rpc.Transport = Transport;
  class Service {
    constructor(transport) {
      this.transport = transport;
    }
    request(m, options) {
      let r = new Request(this, m, options);
      this.transport.requests.next(r);
      return r;
    }
  }
  rpc.Service = Service;
})(rpc || (rpc = {}));
