import { isPacked, isZero, PBType, WireType, wireTypes, zero } from './types';
import { BinaryWriter } from './binary.writer';
import { BinaryReader } from './binary.reader';
export class ProtoMessage {
  constructor(data) {
    if (data) this.merge(data);
  }
  static create(value) {
    let msg = value || {};
    for (const f of this.constructor._fields.values()) f.oneof ? msg[f.oneof] = {
      oneofKind: undefined
    } : msg[f.name] = zero(f);
    return msg;
  }
  merge(src) {
    for (const f of this.constructor._fields.values()) {
      let t = src;
      let dst = this;
      if (f.oneof) {
        t = t[f.oneof];
        if (!t || !(f.name in t)) continue;
        dst[f.oneof] = {
          oneofKind: f.name
        };
        dst = dst[f.oneof];
      }
      t = t[f.name];
      if (!t) continue;
      let ctor = f.ctor ? f.ctor() : null;
      if (f.map) {
        dst[f.name] = {};
        for (const k in t) {
          dst[f.name][k] = !ctor || Number.isInteger(ctor[1]) || t.constructor == ctor ? t[k] : new ctor[1](t[k]);
        }
      } else if (f.repeated) {
        dst[f.name] = t.map(x => !ctor || x.constructor == ctor ? x : new ctor(x));
      } else {
        dst[f.name] = !ctor || t.constructor == ctor ? t : new ctor(t);
      }
    }
  }
  static fromBinaryMerge(data, x) {
    return x._binaryRead(new BinaryReader(data));
  }
  _binaryRead(r, length) {
    const fields = this.constructor._fields;
    for (const end = length === undefined ? r.len : r.pos + length; r.pos < end;) {
      const [n, wireType] = r.tag(),
        f = fields.get(n);
      if (!f || f.type == PBType.GROUP || wireType == WireType.StartGroup || wireType == WireType.EndGroup) {
        r.skip(wireType);
        continue;
      }
      let dst = this;
      if (f.oneof) {
        dst = dst[f.oneof];
        if (dst.oneofKind !== f.name) dst = dst[f.oneof] = {
          oneofKind: f.name
        };
      }
      let reader = r.getReader(f);
      if (f.map) {
        if (!dst[f.name] || typeof dst[f.name] !== 'object') dst[f.name] = {};
        for (let e = r.uint32() + r.pos; r.pos < e;) {
          const [k, v] = reader(r);
          dst[f.name][k] = v;
        }
      } else if (f.repeated) {
        if (!Array.isArray(dst[f.name])) dst[f.name] = [];
        if (isPacked(f.type)) {
          for (let e = r.uint32() + r.pos; r.pos < e;) dst[f.name].push(reader(r));
        } else {
          dst[f.name].push(reader(r));
        }
      } else {
        dst[f.name] = reader(r);
      }
    }
    return this;
  }
  toBinary() {
    let w = new BinaryWriter();
    this._binaryWrite(w);
    return w.finalize();
  }
  _binaryWrite(w) {
    for (const [n, f] of this.constructor._fields.entries()) {
      const t = this[f.name];
      if (isZero(f, t)) continue;
      if (f.type == PBType.MESSAGE) {
        if (f.map) {
          const ctor = f.ctor();
          const wt = [wireTypes[ctor[0]], Number.isInteger(ctor[1]) ? wireTypes[ctor[1]] : WireType.LengthDelimited];
          const keyWriter = BinaryWriter.writers.get(ctor[0]);
          const vWriter = Number.isInteger(ctor[1]) ? BinaryWriter.writers.get(ctor[1]) : (t, d) => d._binaryWrite(t);
          for (let i of Object.keys(t)) {
            keyWriter(w.tag(n, WireType.LengthDelimited).push().tag(1, wireTypes[wt[0]]), i);
            vWriter(w.tag(2, wireTypes[wt[1]]), t[i]).pop();
          }
        } else {
          if (f.repeated) {
            for (let i = 0; i < t.length; i++) t[i]._binaryWrite(w.tag(n, WireType.LengthDelimited).push()).pop();
          } else {
            t._binaryWrite(w.tag(n, WireType.LengthDelimited).push()).pop();
          }
        }
      } else {
        let writer = w.getWriter(f);
        if (f.repeated) {
          if (isPacked(f.type)) {
            w.tag(n, WireType.LengthDelimited).push();
            for (let i = 0; i < t.length; i++) writer(w, t[i]);
            w.pop();
          } else {
            for (let i = 0; i < t.length; i++) writer(w.tag(n, wireTypes[f.type]), t[i]);
          }
        } else {
          writer(w.tag(n, wireTypes[f.type]), t);
        }
      }
    }
    return w;
  }
}
