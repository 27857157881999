// Code generated by pbe. DO NOT EDIT.
import * as $services_antibiogram_v1 from './services/antibiogram/v1/antibiogram.pbe';
import * as $services_auth_v1 from './services/auth/auth.pbe';
import * as $services_clinical_document_v1 from './services/clinical_document/v1/clinical_document.pbe';
import * as $services_core_v1 from './services/core/v1/core.pbe';
import * as $services_cqm_v1 from './services/cqm/v1/cqm.pbe';
import * as $services_drug_v1 from './services/drug/v1/drug.pbe';
import * as $services_drugbank_v1 from './services/drugbank/v1/drugbank.pbe';
import * as $services_fax_v1 from './services/fax/v1/fax.pbe';
import * as $services_image_recognition_v1 from './services/image_recognition/v1/image_recognition.pbe';
import * as $services_implantable_devices_v1 from './services/implantable_devices/v1/implantable_devices.pbe';
import * as $services_imports_v1 from './services/imports/v1/imports.pbe';
import * as $services_lab_v1 from './services/lab/v1/lab.pbe';
import * as $services_medication_v1 from './services/medication/v1/medication.pbe';
import * as $services_notification_v1 from './services/notification/v1/notification.pbe';
import * as $services_patient_v1 from './services/patient/v1/patient.pbe';
import * as $services_pdf_v1 from './services/pdf/v1/pdf.pbe';
import * as $services_pharmacy_v1 from './services/pharmacy/v1/pharmacy.pbe';
import * as $services_phimail_v1 from './services/phimail/v1/phimail.pbe';
import * as $services_portal_v1 from './services/portal/v1/portal.pbe';
import * as $services_secret_v1 from './services/secret/v1/secret.pbe';
import * as $services_sftp_v1 from './services/sftp/v1/sftp.pbe';
import * as $services_snomed_ct_v1 from './services/snomed_ct/v1/snomed_ct.pbe';
export var services;
(function (services) {
  var antibiogram;
  (function (antibiogram) {
    antibiogram.v1 = $services_antibiogram_v1;
  })(antibiogram = services.antibiogram || (services.antibiogram = {}));
})(services || (services = {}));
(function (services) {
  var auth;
  (function (auth) {
    auth.v1 = $services_auth_v1;
  })(auth = services.auth || (services.auth = {}));
})(services || (services = {}));
(function (services) {
  var clinical_document;
  (function (clinical_document) {
    clinical_document.v1 = $services_clinical_document_v1;
  })(clinical_document = services.clinical_document || (services.clinical_document = {}));
})(services || (services = {}));
(function (services) {
  var core;
  (function (core) {
    core.v1 = $services_core_v1;
  })(core = services.core || (services.core = {}));
})(services || (services = {}));
(function (services) {
  var cqm;
  (function (cqm) {
    cqm.v1 = $services_cqm_v1;
  })(cqm = services.cqm || (services.cqm = {}));
})(services || (services = {}));
(function (services) {
  var drug;
  (function (drug) {
    drug.v1 = $services_drug_v1;
  })(drug = services.drug || (services.drug = {}));
})(services || (services = {}));
(function (services) {
  var drugbank;
  (function (drugbank) {
    drugbank.v1 = $services_drugbank_v1;
  })(drugbank = services.drugbank || (services.drugbank = {}));
})(services || (services = {}));
(function (services) {
  var fax;
  (function (fax) {
    fax.v1 = $services_fax_v1;
  })(fax = services.fax || (services.fax = {}));
})(services || (services = {}));
(function (services) {
  var image_recognition;
  (function (image_recognition) {
    image_recognition.v1 = $services_image_recognition_v1;
  })(image_recognition = services.image_recognition || (services.image_recognition = {}));
})(services || (services = {}));
(function (services) {
  var implantable_devices;
  (function (implantable_devices) {
    implantable_devices.v1 = $services_implantable_devices_v1;
  })(implantable_devices = services.implantable_devices || (services.implantable_devices = {}));
})(services || (services = {}));
(function (services) {
  var imports;
  (function (imports) {
    imports.v1 = $services_imports_v1;
  })(imports = services.imports || (services.imports = {}));
})(services || (services = {}));
(function (services) {
  var lab;
  (function (lab) {
    lab.v1 = $services_lab_v1;
  })(lab = services.lab || (services.lab = {}));
})(services || (services = {}));
(function (services) {
  var medication;
  (function (medication) {
    medication.v1 = $services_medication_v1;
  })(medication = services.medication || (services.medication = {}));
})(services || (services = {}));
(function (services) {
  var notification;
  (function (notification) {
    notification.v1 = $services_notification_v1;
  })(notification = services.notification || (services.notification = {}));
})(services || (services = {}));
(function (services) {
  var patient;
  (function (patient) {
    patient.v1 = $services_patient_v1;
  })(patient = services.patient || (services.patient = {}));
})(services || (services = {}));
(function (services) {
  var pdf;
  (function (pdf) {
    pdf.v1 = $services_pdf_v1;
  })(pdf = services.pdf || (services.pdf = {}));
})(services || (services = {}));
(function (services) {
  var pharmacy;
  (function (pharmacy) {
    pharmacy.v1 = $services_pharmacy_v1;
  })(pharmacy = services.pharmacy || (services.pharmacy = {}));
})(services || (services = {}));
(function (services) {
  var phimail;
  (function (phimail) {
    phimail.v1 = $services_phimail_v1;
  })(phimail = services.phimail || (services.phimail = {}));
})(services || (services = {}));
(function (services) {
  var portal;
  (function (portal) {
    portal.v1 = $services_portal_v1;
  })(portal = services.portal || (services.portal = {}));
})(services || (services = {}));
(function (services) {
  var secret;
  (function (secret) {
    secret.v1 = $services_secret_v1;
  })(secret = services.secret || (services.secret = {}));
})(services || (services = {}));
(function (services) {
  var sftp;
  (function (sftp) {
    sftp.v1 = $services_sftp_v1;
  })(sftp = services.sftp || (services.sftp = {}));
})(services || (services = {}));
(function (services) {
  var snomed_ct;
  (function (snomed_ct) {
    snomed_ct.v1 = $services_snomed_ct_v1;
  })(snomed_ct = services.snomed_ct || (services.snomed_ct = {}));
})(services || (services = {}));
export const rpcServices = [services.antibiogram.v1.AntibiogramService, services.auth.v1.AuthService, services.clinical_document.v1.ClinicalDocumentService, services.core.v1.DrugService, services.core.v1.UserService, services.cqm.v1.CQMService, services.drug.v1.DrugService, services.fax.v1.FaxService, services.image_recognition.v1.ImageRecognitionService, services.implantable_devices.v1.ImplantableDevicesWebService, services.imports.v1.ImportService, services.lab.v1.LabService, services.lab.v1.LabWebService, services.medication.v1.MedicationService, services.medication.v1.MedicationWebService, services.notification.v1.NotificationFrontendService, services.notification.v1.NotificationService, services.pdf.v1.PDFService, services.pharmacy.v1.PharmacyService, services.pharmacy.v1.PharmacyWebService, services.phimail.v1.PhimailService, services.phimail.v1.PhimailWebService, services.sftp.v1.SFTP];
