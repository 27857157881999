import { PBType } from './types';
function bit64DataView(value) {
  const t = new DataView(new ArrayBuffer(8));
  t.setBigUint64(0, BigInt(value), true);
  return t;
}
function split64bit(value) {
  const t = bit64DataView(value);
  return [t.getUint32(0, true), t.getInt32(4, true)];
}
function splitU64bit(value) {
  const t = bit64DataView(value);
  return [t.getUint32(0, true), t.getUint32(4, true)];
}
export class BinaryWriter {
  constructor(textEncoder) {
    this.bufs = [];
    this.buf = [];
    this.stack = [];
    this.textEncoder = textEncoder !== null && textEncoder !== void 0 ? textEncoder : new TextEncoder();
  }
  getWriter(f) {
    return BinaryWriter.writers.get(f.type);
  }
  finalize() {
    this.bufs.push(new Uint8Array(this.buf));
    let len = 0;
    for (let i = 0; i < this.bufs.length; i++) len += this.bufs[i].length;
    let bytes = new Uint8Array(len);
    let offset = 0;
    for (let i = 0; i < this.bufs.length; i++) {
      bytes.set(this.bufs[i], offset);
      offset += this.bufs[i].length;
    }
    this.bufs = [];
    return bytes;
  }
  push() {
    this.stack.push({
      bufs: this.bufs,
      buf: this.buf
    });
    this.bufs = [];
    this.buf = [];
    return this;
  }
  pop() {
    let b = this.finalize();
    let prev = this.stack.pop();
    if (!prev) throw new Error('invalid state, fork stack empty');
    this.bufs = prev.bufs;
    this.buf = prev.buf;
    this.uint32(b.byteLength);
    return this.append(b);
  }
  tag(n, code) {
    return this.uint32((n << 3 | code) >>> 0);
  }
  append(b) {
    if (this.buf.length) {
      this.bufs.push(new Uint8Array(this.buf));
      this.buf = [];
    }
    this.bufs.push(b);
    return this;
  }
  varint32(x) {
    if (x >= 0) {
      for (; x > 0x7f; x >>>= 7) this.buf.push(x & 0x7f | 0x80);
      this.buf.push(x);
    } else {
      for (let i = 0; i < 9; i++, x >>= 7) this.buf.push(x & 0x7f | 0x80);
      this.buf.push(1);
    }
    return this;
  }
  uint32(x) {
    for (; x > 0x7f; x >>>= 7) this.buf.push(x & 0x7f | 0x80);
    this.buf.push(x);
    return this;
  }
  int32(value) {
    return this.varint32(value);
  }
  bool(x) {
    this.buf.push(x ? 1 : 0);
    return this;
  }
  bytes(x) {
    this.uint32(x.byteLength);
    return this.append(x);
  }
  string(x) {
    let b = this.textEncoder.encode(x);
    this.uint32(b.byteLength);
    return this.append(b);
  }
  float(x) {
    let b = new Uint8Array(4);
    new DataView(b.buffer).setFloat32(0, x, true);
    return this.append(b);
  }
  double(x) {
    let b = new Uint8Array(8);
    new DataView(b.buffer).setFloat64(0, x, true);
    return this.append(b);
  }
  fixed32(x) {
    let b = new Uint8Array(4);
    new DataView(b.buffer).setUint32(0, x, true);
    return this.append(b);
  }
  sfixed32(x) {
    let b = new Uint8Array(4);
    new DataView(b.buffer).setInt32(0, x, true);
    return this.append(b);
  }
  sint32(x) {
    return this.varint32((x << 1 ^ x >> 31) >>> 0);
  }
  varint64(lo, hi) {
    for (let i = 0; i < 28; i = i + 7) {
      const x = lo >>> i;
      const n = !(x >>> 7 == 0 && hi == 0);
      this.buf.push((n ? x | 0x80 : x) & 0xff);
      if (!n) return this;
    }
    const x = lo >>> 28 & 0x0f | (hi & 0x07) << 4;
    const n = !(hi >> 3 == 0);
    this.buf.push((n ? x | 0x80 : x) & 0xff);
    if (!n) return this;
    for (let i = 3; i < 31; i = i + 7) {
      const x = hi >>> i;
      const n = !(x >>> 7 == 0);
      this.buf.push((n ? x | 0x80 : x) & 0xff);
      if (!n) return this;
    }
    this.buf.push(hi >>> 31 & 1);
    return this;
  }
  sfixed64(value) {
    return this.append(bit64DataView(value).buffer);
  }
  fixed64(value) {
    return this.append(bit64DataView(value).buffer);
  }
  int64(value) {
    return this.varint64(...split64bit(value));
  }
  sint64(value) {
    let t = split64bit(value),
      sign = t[1] >> 31,
      lo = t[0] << 1 ^ sign,
      hi = (t[1] << 1 | t[0] >>> 31) ^ sign;
    return this.varint64(lo, hi);
  }
  uint64(value) {
    return this.varint64(...splitU64bit(value));
  }
}
BinaryWriter.writers = new Map([[PBType.DOUBLE, (t, d) => t.double(d)], [PBType.FLOAT, (t, d) => t.float(d)], [PBType.INT64, (t, d) => t.int64(d)], [PBType.UINT64, (t, d) => t.uint64(d)], [PBType.INT32, (t, d) => t.int32(d)], [PBType.FIXED64, (t, d) => t.fixed64(d)], [PBType.FIXED32, (t, d) => t.fixed32(d)], [PBType.BOOL, (t, d) => t.bool(d)], [PBType.STRING, (t, d) => t.string(d)], [PBType.BYTES, (t, d) => t.bytes(d)], [PBType.UINT32, (t, d) => t.uint32(d)], [PBType.ENUM, (t, d) => t.int32(d)], [PBType.SFIXED32, (t, d) => t.sfixed32(d)], [PBType.SFIXED64, (t, d) => t.sfixed64(d)], [PBType.SINT32, (t, d) => t.sint32(d)], [PBType.SINT64, (t, d) => t.sint64(d)]]);
