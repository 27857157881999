// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '@pbe/runtime';
export class Config extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Config());
  }
}
Config._fqn = 'services.patient.v1.Config';
Config._fields = NewFields([[1, {
  name: 'database',
  json: 'database',
  type: 11
}], [2, {
  name: 'sftp',
  json: 'sftp',
  type: 11,
  ctor: () => SFTPConfig
}]]);
export class SFTPConfig extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new SFTPConfig());
  }
}
SFTPConfig._fqn = 'services.patient.v1.SFTPConfig';
SFTPConfig._fields = NewFields([[1, {
  name: 'host',
  json: 'host',
  type: 9
}], [2, {
  name: 'port',
  json: 'port',
  type: 13
}], [3, {
  name: 'user',
  json: 'user',
  type: 9
}], [4, {
  name: 'password',
  json: 'password',
  type: 9
}], [5, {
  name: 'folder',
  json: 'folder',
  type: 9
}]]);
