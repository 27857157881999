import { PBType, WireType } from './types';
const utf8decoder = new TextDecoder('utf-8');
export class BinaryReader {
  constructor(buf) {
    this.buf = buf;
    this.len = buf.length;
    this.pos = 0;
    this.view = new DataView(buf.buffer, buf.byteOffset, buf.byteLength);
  }
  checkPos() {
    if (this.pos > this.len) throw new RangeError();
  }
  getReader(f) {
    if (f.type == PBType.MESSAGE) {
      if (f.map) {
        let ctor = f.ctor();
        return r => {
          r.tag();
          let k = BinaryReader.readers.get(ctor[0])(r);
          r.tag();
          if (Number.isInteger(ctor[1])) return [k, BinaryReader.readers.get(ctor[1])(r)];else return [k, new f.ctor[1]()._binaryRead(r, r.uint32())];
        };
      } else {
        return r => new (f.ctor())()._binaryRead(r, r.uint32());
      }
    } else {
      return BinaryReader.readers.get(f.type);
    }
  }
  map() {
    this.tag();
  }
  tag() {
    let tag = this.uint32(),
      fieldNo = tag >>> 3,
      wireType = tag & 7;
    if (fieldNo <= 0 || wireType < 0 || wireType > 5) throw new Error('illegal tag: field no ' + fieldNo + ' wire type ' + wireType);
    return [fieldNo, wireType];
  }
  varint32_() {
    let b = this.buf[this.pos++];
    let r = b & 0x7F;
    if ((b & 0x80) == 0) return r;
    b = this.buf[this.pos++];
    r |= (b & 0x7F) << 7;
    if ((b & 0x80) == 0) return r;
    b = this.buf[this.pos++];
    r |= (b & 0x7F) << 14;
    if ((b & 0x80) == 0) return r;
    b = this.buf[this.pos++];
    r |= (b & 0x7F) << 21;
    if ((b & 0x80) == 0) return r;
    // Extract only last 4 bits
    b = this.buf[this.pos++];
    r |= (b & 0x0F) << 28;
    for (let readBytes = 5; (b & 0x80) !== 0 && readBytes < 10; readBytes++) b = this.buf[this.pos++];
    if ((b & 0x80) != 0) throw new Error('invalid varint');
    return r;
  }
  varint64_() {
    let lo = 0,
      hi = 0;
    for (let shift = 0; shift < 28; shift += 7) {
      let b = this.buf[this.pos++];
      lo |= (b & 0x7F) << shift;
      if ((b & 0x80) == 0) return [lo, hi];
    }
    let m = this.buf[this.pos++];
    lo |= (m & 0x0F) << 28;
    hi = (m & 0x70) >> 4;
    if ((m & 0x80) == 0) return [lo, hi];
    for (let shift = 3; shift <= 31; shift += 7) {
      let b = this.buf[this.pos++];
      hi |= (b & 0x7F) << shift;
      if ((b & 0x80) == 0) return [lo, hi];
    }
    throw new Error('invalid varint');
  }
  uint32() {
    // console.log(":::", this.pos);
    let r = this.varint32_() >>> 0;
    // console.log("r:", 3, r >>> 3, 3 & 7);
    // console.log(":::", this.pos);
    this.checkPos();
    return r;
  }
  skip(wireType) {
    let start = this.pos;
    switch (wireType) {
      case WireType.Bit32:
        this.pos += 4;
        break;
      case WireType.Bit64:
        this.pos += 8;
        break;
      case WireType.LengthDelimited:
        let len = this.uint32();
        this.pos += len;
        break;
      case WireType.Varint:
        while (this.buf[this.pos] & 0x80) this.pos++;
        break;
    }
    this.checkPos();
    return this.buf.slice(start, this.pos);
  }
  varint64() {
    let [lo, hi] = this.varint64_();
    this.checkPos();
    return [lo, hi];
  }
  int32() {
    return this.uint32() | 0;
  }
  sint32() {
    let zze = this.uint32();
    return zze >>> 1 ^ -(zze & 1);
  }
  int64() {
    let [lo, hi] = this.varint64();
    return BigInt(hi) << BigInt(32) | BigInt(lo);
  }
  uint64() {
    let [lo, hi] = this.varint64();
    return BigInt(hi) << BigInt(32) | BigInt(lo);
  }
  sint64() {
    let [lo, hi] = this.varint64();
    let s = -(lo & 1);
    lo = (lo >>> 1 | (hi & 1) << 31) ^ s;
    hi = hi >>> 1 ^ s;
    return BigInt(hi) << BigInt(32) | BigInt(lo);
  }
  bool() {
    let [lo, hi] = this.varint64();
    return lo !== 0 || hi !== 0;
  }
  fixed32() {
    return this.view.getUint32((this.pos += 4) - 4, true);
  }
  sfixed32() {
    return this.view.getInt32((this.pos += 4) - 4, true);
  }
  fixed64() {
    let [lo, hi] = this.varint64();
    return BigInt(hi) << BigInt(32) | BigInt(lo);
  }
  sfixed64() {
    let [lo, hi] = this.varint64();
    return BigInt(hi) << BigInt(32) | BigInt(lo);
  }
  float() {
    return this.view.getFloat32((this.pos += 4) - 4, true);
  }
  double() {
    return this.view.getFloat64((this.pos += 8) - 8, true);
  }
  bytes() {
    // console.log("before:", this.pos, this.len);
    let len = this.uint32();
    // console.log("bytes len:", len, this.pos, this.len);
    let start = this.pos;
    this.pos += len;
    this.checkPos();
    return this.buf.slice(start, this.pos);
  }
  string() {
    return utf8decoder.decode(this.bytes());
  }
}
BinaryReader.readers = new Map([[PBType.DOUBLE, t => t.double()], [PBType.FLOAT, t => t.float()], [PBType.INT64, t => t.int64()], [PBType.UINT64, t => t.uint64()], [PBType.INT32, t => t.int32()], [PBType.FIXED64, t => t.fixed64()], [PBType.FIXED32, t => t.fixed32()], [PBType.BOOL, t => t.bool()], [PBType.STRING, t => t.string()], [PBType.BYTES, t => t.bytes()], [PBType.UINT32, t => t.uint32()], [PBType.ENUM, t => t.int32()], [PBType.SFIXED32, t => t.sfixed32()], [PBType.SFIXED64, t => t.sfixed64()], [PBType.SINT32, t => t.sint32()], [PBType.SINT64, t => t.sint64()]]);
