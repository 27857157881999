// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '../../runtime';
export class Field extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Field());
  }
}
Field._fqn = 'dart.Field';
Field._fields = NewFields([[1, {
  name: 'hive',
  json: 'hive',
  type: 8
}], [2, {
  name: 'hiveId',
  json: 'hive_id',
  type: 5
}], [3, {
  name: 'default_',
  json: 'default',
  type: 9
}], [4, {
  name: 'nullable',
  json: 'nullable',
  type: 8
}], [5, {
  name: 'stream',
  json: 'stream',
  type: 8
}]]);
export class File extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new File());
  }
}
File._fqn = 'dart.File';
File._fields = NewFields();
export class Message extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Message());
  }
}
Message._fqn = 'dart.Message';
Message._fields = NewFields([[1, {
  name: 'hive',
  json: 'hive',
  type: 13
}]]);
export class Service extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Service());
  }
}
Service._fqn = 'dart.Service';
Service._fields = NewFields();
