// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '../../runtime';
export class Field extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Field());
  }
}
Field._fqn = 'nanopb.Field';
Field._fields = NewFields([[1, {
  name: 'maxSize',
  json: 'max_size',
  type: 5
}], [2, {
  name: 'maxLength',
  json: 'max_length',
  type: 5
}], [3, {
  name: 'maxCount',
  json: 'max_count',
  type: 5
}]]);
export class File extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new File());
  }
}
File._fqn = 'nanopb.File';
File._fields = NewFields();
export class Message extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Message());
  }
}
Message._fqn = 'nanopb.Message';
Message._fields = NewFields();
export class Service extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Service());
  }
}
Service._fqn = 'nanopb.Service';
Service._fields = NewFields();
