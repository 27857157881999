// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage, builtin } from '@pbe/runtime';
export class Concept extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Concept());
  }
}
Concept._fqn = 'services.snomed_ct.v1.Concept';
Concept._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 3
}], [3, {
  name: 'effectiveTime',
  json: 'effective_time',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [4, {
  name: 'active',
  json: 'active',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [5, {
  name: 'term',
  json: 'term',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [1024, {
  name: 'descriptions',
  json: 'descriptions',
  type: 11,
  repeated: true,
  ctor: () => Description
}], [1025, {
  name: 'relationshipsAsSource',
  json: 'relationships_as_source',
  type: 11,
  repeated: true,
  ctor: () => Relationship
}], [1026, {
  name: 'relationshipsAsDestination',
  json: 'relationships_as_destination',
  type: 11,
  repeated: true,
  ctor: () => Relationship
}]]);
export class Description extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Description());
  }
}
Description._fqn = 'services.snomed_ct.v1.Description';
Description._fields = NewFields([[2, {
  name: 'id',
  json: 'id',
  type: 3
}], [3, {
  name: 'effectiveTime',
  json: 'effective_time',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [4, {
  name: 'active',
  json: 'active',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [5, {
  name: 'concept',
  json: 'concept',
  type: 11,
  ctor: () => Concept
}], [6, {
  name: 'typeId',
  json: 'type_id',
  type: 3
}], [7, {
  name: 'term',
  json: 'term',
  type: 11,
  ctor: () => builtin.values.StringValue
}]]);
export class Relationship extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Relationship());
  }
}
Relationship._fqn = 'services.snomed_ct.v1.Relationship';
Relationship._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [3, {
  name: 'effectiveTime',
  json: 'effective_time',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [4, {
  name: 'active',
  json: 'active',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [5, {
  name: 'source',
  json: 'source',
  type: 11,
  ctor: () => Concept
}], [6, {
  name: 'destination',
  json: 'destination',
  type: 11,
  ctor: () => Concept
}], [7, {
  name: 'typeId',
  json: 'type_id',
  type: 3
}]]);
