// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '../../runtime';
export class FilterGroup extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new FilterGroup());
  }
}
FilterGroup._fqn = 'filter.FilterGroup';
FilterGroup._fields = NewFields([[1, {
  name: 'name',
  json: 'name',
  type: 9
}], [2, {
  name: 'message',
  json: 'message',
  type: 9
}], [3, {
  name: 'paths',
  json: 'paths',
  type: 9
}]]);
export class FilterPaths extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new FilterPaths());
  }
}
FilterPaths._fqn = 'filter.FilterPaths';
FilterPaths._fields = NewFields([[1, {
  name: 'paths',
  json: 'paths',
  type: 9
}]]);
