// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage } from '@pbe/runtime';
export class Config extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new Config());
  }
}
Config._fqn = 'services.portal.v1.Config';
Config._fields = NewFields([[1, {
  name: 'database',
  json: 'database',
  type: 11
}]]);
