// Code generated by pbe. DO NOT EDIT.
import { NewFields, ProtoMessage, builtin } from '@pbe/runtime';
export class DrugbankDrug extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankDrug());
  }
}
DrugbankDrug._fqn = 'services.drugbank.v1.DrugbankDrug';
DrugbankDrug._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 3
}], [2, {
  name: 'type',
  json: 'type',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [3, {
  name: 'drugbankId',
  json: 'drugbank_id',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [4, {
  name: 'name',
  json: 'name',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [5, {
  name: 'state',
  json: 'state',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [6, {
  name: 'description',
  json: 'description',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [7, {
  name: 'simpleDescription',
  json: 'simple_description',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [8, {
  name: 'clinicalDescription',
  json: 'clinical_description',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [9, {
  name: 'casNumber',
  json: 'cas_number',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [10, {
  name: 'proteinFormula',
  json: 'protein_formula',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [11, {
  name: 'proteinWeight',
  json: 'protein_weight',
  type: 11,
  ctor: () => builtin.values.FloatValue
}], [12, {
  name: 'investigational',
  json: 'investigational',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [13, {
  name: 'approved',
  json: 'approved',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [14, {
  name: 'vetApproved',
  json: 'vet_approved',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [15, {
  name: 'experimental',
  json: 'experimental',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [16, {
  name: 'nutraceutical',
  json: 'nutraceutical',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [17, {
  name: 'illicit',
  json: 'illicit',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [18, {
  name: 'withdrawn',
  json: 'withdrawn',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [19, {
  name: 'moldbMonoMass',
  json: 'moldb_mono_mass',
  type: 11,
  ctor: () => builtin.values.FloatValue
}], [20, {
  name: 'moldbInchi',
  json: 'moldb_inchi',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [21, {
  name: 'moldbInchikey',
  json: 'moldb_inchikey',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [22, {
  name: 'moldbSmiles',
  json: 'moldb_smiles',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [23, {
  name: 'moldbAverageMass',
  json: 'moldb_average_mass',
  type: 11,
  ctor: () => builtin.values.FloatValue
}], [24, {
  name: 'moldbFormula',
  json: 'moldb_formula',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [25, {
  name: 'proteinWeightDetails',
  json: 'protein_weight_details',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [26, {
  name: 'biotechKind',
  json: 'biotech_kind',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [1024, {
  name: 'drugbankIngredients',
  json: 'drugbank_ingredients',
  type: 11,
  repeated: true,
  ctor: () => DrugbankIngredients
}], [1025, {
  name: 'drugbankProductConceptComponents',
  json: 'drugbank_product_concept_components',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProductConceptComponent
}]]);
export class DrugbankIngredients extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankIngredients());
  }
}
DrugbankIngredients._fqn = 'services.drugbank.v1.DrugbankIngredients';
DrugbankIngredients._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'drug',
  json: 'drug',
  type: 11,
  ctor: () => DrugbankDrug
}], [3, {
  name: 'saltId',
  json: 'salt_id',
  type: 11,
  ctor: () => builtin.values.Int32Value
}], [4, {
  name: 'product',
  json: 'product',
  type: 11,
  ctor: () => DrugbankProduct
}], [5, {
  name: 'strength',
  json: 'strength',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [6, {
  name: 'strengthNumber',
  json: 'strength_number',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [7, {
  name: 'strengthUnit',
  json: 'strength_unit',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [8, {
  name: 'clinicallyRelevant',
  json: 'clinically_relevant',
  type: 11,
  ctor: () => builtin.values.BoolValue
}]]);
export class DrugbankProduct extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProduct());
  }
}
DrugbankProduct._fqn = 'services.drugbank.v1.DrugbankProduct';
DrugbankProduct._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 3
}], [2, {
  name: 'labellerId',
  json: 'labeller_id',
  type: 11,
  ctor: () => builtin.values.Int32Value
}], [3, {
  name: 'name',
  json: 'name',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [4, {
  name: 'ndcProductCode',
  json: 'ndc_product_code',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [5, {
  name: 'startedMarketingOn',
  json: 'started_marketing_on',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [6, {
  name: 'endedMarketingOn',
  json: 'ended_marketing_on',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [7, {
  name: 'dosageForm',
  json: 'dosage_form',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [8, {
  name: 'strengthKey',
  json: 'strength_key',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [9, {
  name: 'route',
  json: 'route',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [10, {
  name: 'applicationNumber',
  json: 'application_number',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [11, {
  name: 'generic',
  json: 'generic',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [12, {
  name: 'otc',
  json: 'otc',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [13, {
  name: 'approved',
  json: 'approved',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [14, {
  name: 'country',
  json: 'country',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [15, {
  name: 'dpdId',
  json: 'dpd_id',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [16, {
  name: 'mixture',
  json: 'mixture',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [17, {
  name: 'approvedOn',
  json: 'approved_on',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [18, {
  name: 'schedule',
  json: 'schedule',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [19, {
  name: 'prescribableName',
  json: 'prescribable_name',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [20, {
  name: 'emaProductCode',
  json: 'ema_product_code',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [21, {
  name: 'emaMaNumber',
  json: 'ema_ma_number',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [22, {
  name: 'standing',
  json: 'standing',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [23, {
  name: 'standingUpdatedOn',
  json: 'standing_updated_on',
  type: 11,
  ctor: () => builtin.values.Timestamp
}], [24, {
  name: 'standingReason',
  json: 'standing_reason',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [25, {
  name: 'jurisdictionMarketingCategory',
  json: 'jurisdiction_marketing_category',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [26, {
  name: 'branded',
  json: 'branded',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [27, {
  name: 'prescription',
  json: 'prescription',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [28, {
  name: 'unapproved',
  json: 'unapproved',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [29, {
  name: 'vaccine',
  json: 'vaccine',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [30, {
  name: 'allergenic',
  json: 'allergenic',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [31, {
  name: 'cosmetic',
  json: 'cosmetic',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [32, {
  name: 'kit',
  json: 'kit',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [33, {
  name: 'solo',
  json: 'solo',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [34, {
  name: 'available',
  json: 'available',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [35, {
  name: 'ayvaDdid',
  json: 'ayva_ddid',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [36, {
  name: 'isRepresentativeProduct',
  json: 'is_representative_product',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [1024, {
  name: 'drugbankIngredients',
  json: 'drugbank_ingredients',
  type: 11,
  repeated: true,
  ctor: () => DrugbankIngredients
}], [1025, {
  name: 'drugbankProductConceptProducts',
  json: 'drugbank_product_concept_products',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProductConceptProduct
}]]);
export class DrugbankProductConcept extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProductConcept());
  }
}
DrugbankProductConcept._fqn = 'services.drugbank.v1.DrugbankProductConcept';
DrugbankProductConcept._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 3
}], [2, {
  name: 'drugbankId',
  json: 'drugbank_id',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [3, {
  name: 'title',
  json: 'title',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [4, {
  name: 'level',
  json: 'level',
  type: 11,
  ctor: () => builtin.values.Int32Value
}], [5, {
  name: 'standing',
  json: 'standing',
  type: 11,
  ctor: () => builtin.values.Int32Value
}], [6, {
  name: 'ingredientKey',
  json: 'ingredient_key',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [7, {
  name: 'exactIngredientKey',
  json: 'exact_ingredient_key',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [8, {
  name: 'strengthKey',
  json: 'strength_key',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [9, {
  name: 'brand',
  json: 'brand',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [10, {
  name: 'dosageForm',
  json: 'dosage_form',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [11, {
  name: 'route',
  json: 'route',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [12, {
  name: 'availableInUs',
  json: 'available_in_us',
  type: 11,
  ctor: () => builtin.values.BoolValue
}], [1024, {
  name: 'drugbankProductConceptComponents',
  json: 'drugbank_product_concept_components',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProductConceptComponent
}], [1025, {
  name: 'drugbankProductConceptProducts',
  json: 'drugbank_product_concept_products',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProductConceptProduct
}], [1026, {
  name: 'drugbankProductConceptsRxcuis',
  json: 'drugbank_product_concepts_rxcuis',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProductConceptsRxcuis
}], [1027, {
  name: 'parent',
  json: 'parent',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProuctConceptChildren
}], [1028, {
  name: 'child',
  json: 'child',
  type: 11,
  repeated: true,
  ctor: () => DrugbankProuctConceptChildren
}]]);
export class DrugbankProductConceptComponent extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProductConceptComponent());
  }
}
DrugbankProductConceptComponent._fqn = 'services.drugbank.v1.DrugbankProductConceptComponent';
DrugbankProductConceptComponent._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'productConcept',
  json: 'product_concept',
  type: 11,
  ctor: () => DrugbankProductConcept
}], [3, {
  name: 'drug',
  json: 'drug',
  type: 11,
  ctor: () => DrugbankDrug
}], [4, {
  name: 'saltId',
  json: 'salt_id',
  type: 11,
  ctor: () => builtin.values.Int32Value
}], [5, {
  name: 'amount',
  json: 'amount',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [6, {
  name: 'per',
  json: 'per',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [7, {
  name: 'units',
  json: 'units',
  type: 11,
  ctor: () => builtin.values.StringValue
}]]);
export class DrugbankProductConceptProduct extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProductConceptProduct());
  }
}
DrugbankProductConceptProduct._fqn = 'services.drugbank.v1.DrugbankProductConceptProduct';
DrugbankProductConceptProduct._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'productConcept',
  json: 'product_concept',
  type: 11,
  ctor: () => DrugbankProductConcept
}], [3, {
  name: 'product',
  json: 'product',
  type: 11,
  ctor: () => DrugbankProduct
}]]);
export class DrugbankProductConceptsRxcuis extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProductConceptsRxcuis());
  }
}
DrugbankProductConceptsRxcuis._fqn = 'services.drugbank.v1.DrugbankProductConceptsRxcuis';
DrugbankProductConceptsRxcuis._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'productConcept',
  json: 'product_concept',
  type: 11,
  ctor: () => DrugbankProductConcept
}], [3, {
  name: 'rxcui',
  json: 'rxcui',
  type: 11,
  ctor: () => builtin.values.StringValue
}], [4, {
  name: 'title',
  json: 'title',
  type: 11,
  ctor: () => builtin.values.StringValue
}]]);
export class DrugbankProuctConceptChildren extends ProtoMessage {
  static fromBinary(data) {
    return this.fromBinaryMerge(data, new DrugbankProuctConceptChildren());
  }
}
DrugbankProuctConceptChildren._fqn = 'services.drugbank.v1.DrugbankProuctConceptChildren';
DrugbankProuctConceptChildren._fields = NewFields([[1, {
  name: 'id',
  json: 'id',
  type: 5
}], [2, {
  name: 'parent',
  json: 'parent',
  type: 11,
  ctor: () => DrugbankProductConcept
}], [3, {
  name: 'child',
  json: 'child',
  type: 11,
  ctor: () => DrugbankProductConcept
}]]);
